<template>
  <a-modal
    width="900px"
    v-model="isShow"
    centered
    :mask-closable="false"
    title="操作历史"
  >
    <template slot="footer">
      <a-button class="ant-btn-primary" @click="closeModal">
        关闭
      </a-button>
    </template>

    <operation-history-list
      :reference-id="this.referenceId"
      :reference-type="this.referenceType"
      :height="this.height"
    />
  </a-modal>
</template>

<script>
import OperationHistoryList from '@/views/operation_histories/index'

export default {
  name: 'OperationHistoryListModal',
  components: { OperationHistoryList },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    referenceType: {
      type: String,
      required: true
    },
    referenceId: {
      type: Number,
      required: true
    },
    height: {
      type: String,
      default: '400px'
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    closeModal() {
      this.isShow = false
    }
  }
}
</script>
